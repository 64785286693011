import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import StaticPortableText from './static-portable-text';

export default function HoverText({ text }) {
  const [hover, setHover] = useState(null);
  const [projects, setProjects] = useState(null);

  useLayoutEffect(() => {
    setProjects(text.reduce((acc, cur) => {
      return acc.concat(cur.markDefs.filter((el) => el._type === 'projectLink'));
    }, []).map((el) => el.project));
  }, []);

  function getImage(id, width) {
    return getGatsbyImageData(id, { width: width }, {
      projectId: process.env.SANITY_PROJECT_ID,
      dataset: process.env.SANITY_PROJECT_DATASET,
    })?.images.fallback.src;
  }

  const serializers = {
    marks: {
      projectLink: ({ children, mark }) => (
        <Link
          css={css`
            display: inline-block;
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
              color: var(--black);
            }
          `}
          to={`/project/${mark.project?.slug.current}`}
          onMouseEnter={() => setHover(mark.project)}
          onMouseLeave={() => setHover(null)}
        >{children}</Link>
      ),
    },
  }

  return (
    <div>
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: ${(hover?.hoverType === 'color') ? hover.hoverColor?.value : 'transparent'};
          z-index: 1;
          transition: background-color 1s var(--curve);
          transition-delay: ${(hover?.hoverType === 'color') ? '0.6s' : '0s'};
          pointer-events: none;
        `}
      >
        {projects?.length > 0 && projects
          .filter((project) => project?.hoverType === 'image')
          .map((project, i) => (
            <div
              key={i}
              css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              opacity: ${(hover?.id === project.id && project.hoverType === 'image') ? 1 : 0};
              transition: opacity 1s var(--curve);
              transition-delay: ${(hover?.id === project.id && project.hoverType === 'image') ? '0.6s' : '0s'};
            `}
            >
              <img
                css={css`
                max-width: 60vw;
                max-height: 80vh;
              `}
                src={getImage(project.hoverImage?.asset._id, 1188)}
              />
            </div>
          ))
        }
        {projects?.length > 0 && projects
          .filter((project) => project?.hoverType === 'full-bleed-image')
          .map((project) => (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
              `}
            >
              <img
                key={project.id}
                css={css`
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  opacity: ${(hover?.id === project.id && project.hoverType === 'full-bleed-image') ? 1 : 0};
                  transition: opacity 1s var(--curve);
                  transition-delay: ${(hover?.id === project.id && project.hoverType === 'full-bleed-image') ? '0.6s' : '0s'};
                `}
                src={getImage(project.hoverFullBleedImage?.asset._id, 1980)}
              />
            </div>
          ))
        }
      </div>
      <div
        css={css`
          position: relative;
          z-index: 2;

          p, a {
            color: ${(hover) ? 'rgba(1, 1, 1, 0.15)' : 'var(--black)'};
            transition: color 0.6s var(--curve);
          }
        `}
      >
        <StaticPortableText
          blocks={text}
          serializers={serializers}
        />
      </div>
    </div>
  )
}
