import React from 'react';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { css } from '@emotion/react';

export default class StaticPortableText extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.blocks !== nextProps.blocks) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <BlockContent
        className={this.props.className}
        css={css`
          ul {
            padding: 0;
            margin: 2rem 0;

            li {
              list-style: none;

              &::before {
                content: '– ';
              }
            }
          }
        `}
        blocks={this.props.blocks}
        serializers={(this.props.serializers) ? this.props.serializers : this.serializers}
        projectId={process.env.SANITY_PROJECT_ID}
        dataset={process.env.SANITY_PROJECT_DATASET}
        renderContainerOnSingleChild
      />
    )
  }
}
